<template>
<div>
    <div class="breadcrumb_header">
        <div class="breadcrumb">
        <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
        <span class="divider"></span>
        <span>Analyze Pleading</span>
        </div>
    <div class="open_sidebar" @click="toggleSidebar">
      <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
      <p>Open Sidebar</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1 mt-3">
        <div class="d-flex justify-content-start cursor-pointer back" @click="goBack">
      <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
      <span style="margin: 0 8px;">Back</span>
    </div>
    </div>
    <div class="col-md-10 mt-5"> 
        <loader v-if="loading" />
        <div v-else>
        <claim class="mb-4"/> 
        <summarize class="mb-4"/>
        <identify class="mb-4"/>
        </div>


    </div>
    <div class="col-md-1">
    </div>
    </div>
</div>
</template>
<script>
import loader from './loader.vue';
import claim from './claim_table.vue';
import summarize from './summarize_table.vue';
import identify from './identify_table.vue';
export default {
    components: {
        loader,claim,summarize,identify
  },
    data(){
        return{
            loading:true,
            sidebarCollapsed: false,
        }
    },
    mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 7000);
  },
    methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
        this.$router.push({ name: 'ExpertMode' });
        },
    }
}
</script>

<style scoped>
.page-content{
  padding: 0;
}
.breadcrumb_header{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span{
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  overflow-y: auto; /* Allows vertical scrolling if content exceeds the height */
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}

</style>